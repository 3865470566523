import { Col, Row, Switch, SwitchProps, Typography } from 'antd';
import { FC } from 'react';
interface Props extends SwitchProps {
  label?: string;
  labelPosition?: 'top' | 'right';
  cardSize?: 'small';
  color?: string;
}

export const SwitchInputCardMock: FC<Props> = ({ label, cardSize, color, ...rest }) => {
  return (
    <Row style={{ marginBottom: 10 }}>
      <Col style={{ marginRight: 10 }}>
        <Switch {...rest} />
      </Col>
      <Col>
        <Typography.Text ellipsis={{ tooltip: `${label}` }} style={{ fontSize: 15, color }}>
          {label}{' '}
        </Typography.Text>
      </Col>
    </Row>
  );
};
