import { Card, Form, Space, Typography } from 'antd';
import { FieldInput } from 'components/UI/FormItems/FieldInput';
import { SwitchInputCard } from 'components/UI/FormItems/SwitchInputCard';
import React from 'react';

export const GeneralCard: React.FC = () => {
  /* ******************** Renderer ******************** */
  return (
    <Card
      style={{ marginBottom: 5, height: '100%' }}
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
          General
        </Typography.Title>
      }
      bodyStyle={{ padding: 20 }}
      headStyle={{ background: 'rgb(206, 213, 242)' }}>
      <Form layout="vertical">
        <Space size={14} direction="vertical" style={{ width: '100%' }}>
          <SwitchInputCard fieldName="isStandardCostDisplayed" label="Display Standard Cost" />
          <SwitchInputCard fieldName="isNextFlightOutApprovalRequired" label="NFO Approval Required" />
          <FieldInput fieldName="reference1Label" label="Primary Reference Label" />
          <FieldInput fieldName="reference2Label" label="Secondary Reference Label" />
        </Space>
      </Form>
    </Card>
  );
};
