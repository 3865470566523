import { SelectProps, Space, Spin } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { useField } from 'formik';
import { useGetWorldsQuery } from 'redux/services/chuckieSue/worldsApi';
import { FieldInputSelect } from './FieldInputSelect';

interface Props extends SelectProps {
  countryFieldName: string;
  stateFieldName: string;
}

export const CountriesSelectInput = ({ countryFieldName, stateFieldName }: Props): JSX.Element => {
  const [{ value: selectedCountry }] = useField<string>(countryFieldName);

  const { data: countryData, isLoading: isWorldsLoading, isFetching: isWorldsFetching } = useGetWorldsQuery();

  const countries = countryData?.data[0].countries;

  const states = countries?.find((country) => country.alpha2Code === selectedCountry || country.shortName === selectedCountry);

  console.log(states, 'states found');
  const formattedStates = states?.subdivisions.map((state) => state.name);

  return (
    <Spin style={{ width: '100%' }} spinning={isWorldsLoading || isWorldsFetching} indicator={<BlockLoader small direction="loader loader--slideUp" />}>
      <Space direction="vertical" style={{ width: '100%' }} size={15}>
        <FieldInputSelect countryOptions={countries} fieldName={countryFieldName} label="Country" />
        <FieldInputSelect disabled={!selectedCountry} formattedOptions={formattedStates ?? []} fieldName={stateFieldName} label="State" />
      </Space>
    </Spin>
  );
};
