import { Input } from 'antd';
import { Field, FieldProps, useFormikContext } from 'formik';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props {
  fieldName: string;
  placeholder?: string;
  type?: string;
  label: string;
}

export const FieldInput: FC<Props> = ({ fieldName, placeholder, type, label }) => {
  const { handleSubmit } = useFormikContext();

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Field name={fieldName} placeholder={placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        return (
          <div>
            <FormLabel label={label} />
            <Input onKeyDown={handleEnter} onFocus={() => setFieldTouched(fieldName, false)} style={{ borderColor: meta.error && meta.touched ? '#dc3545' : undefined }} type={type} {...field} />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </div>
        );
      }}
    </Field>
  );
};
