import { DeleteOutlined, EditOutlined, HolderOutlined, WarningFilled } from '@ant-design/icons';
import { Button, Card, Col, Modal, Row, Space, Table, Tooltip, Typography, message } from 'antd';
import { CountriesSelectInput } from 'components/UI/FormItems/CountriesSelectInput';
import { FieldInputSelect } from 'components/UI/FormItems/FieldInputSelect';
import { SiteSelectInput } from 'components/UI/FormItems/SiteSelectInput';
import { AddRuleMessage } from 'components/atoms/addRule/AddRuleMessage';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import update from 'immutability-helper';
import _ from 'lodash';
import { CreateSparingRequestType, WarehouseFulfillmentRules, warehouseFulfillmentRulesPayload } from 'models/SparingRequestTypes';
import { useCallback, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useGetWorldsQuery } from 'redux/services/chuckieSue/worldsApi';
import { manager } from 'utils/dndUtils';
import { ReactComponent as CloneIcon } from '../../common/assets/clone.svg';

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

export const NFOFulfillmentTable = (): JSX.Element => {
  const [{ value }, , { setValue }] = useField<WarehouseFulfillmentRules[]>('nfoWarehouseFulfillmentRules');
  const { values: formVals, setValues } = useFormikContext<CreateSparingRequestType>();
  const { data: countryData, isLoading: isWorldsLoading, isFetching: isWorldsFetching } = useGetWorldsQuery();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [isClone, setIsClone] = useState<boolean>(false);
  const [modal, contextHolder] = Modal.useModal();

  const [{ value: record }, , { setValue: setRecord }] = useField<WarehouseFulfillmentRules>(`nfoWarehouseFulfillmentRules[${index}]`);

  const type = 'DraggableBodyRow';

  const warehouseFormik = useFormik<WarehouseFulfillmentRules>({
    enableReinitialize: true,
    validationSchema: warehouseFulfillmentRulesPayload,
    initialValues: {
      countryCode: '',
      warehouseId: '',
      warehouseType: '',
      priority: value?.length ? value.length + 1 : 1,
      subdivisionCode: ''
    },
    onSubmit: async (values) => {
      try {
        await warehouseFulfillmentRulesPayload.validate(values);
      } catch (err) {
        // warehouseFormik.setFieldError('warehouseId', 'A warehouse or warehouse type is required to continue');
        // warehouseFormik.setFieldError('warehouseType', 'A warehouse or warehouse type is required to continue');
        message.error('A warehouse or warehouse type is required to continue');

        return;
      }

      if (
        formVals?.nfoWarehouseFulfillmentRules?.find(
          (rule) => rule.countryCode === values.countryCode && rule.warehouseType === values.warehouseType && rule.subdivisionCode === values.subdivisionCode && rule.warehouseId === values.warehouseId
        )
      ) {
        message.error('Rule already exists, no duplicates allowed');

        return;
      }

      const foundCountry = countryData?.data[0]?.countries?.find((country) => country.shortName === values.countryCode)?.alpha2Code;
      const subdivisionCode = countryData?.data[0]?.countries
        ?.find((country) => country.shortName === values.countryCode)
        ?.subdivisions.find((state) => state.name === values.subdivisionCode)?.localCode;

      setValues((prev) => ({
        ...prev,
        nfoWarehouseFulfillmentRules: [...value, { ...values, countryCode: foundCountry, subdivisionCode }]
      }));

      setIsOpen(false);
      warehouseFormik.resetForm();
    }
  });
  const editWarehouseFormik = useFormik<WarehouseFulfillmentRules>({
    enableReinitialize: true,
    validationSchema: warehouseFulfillmentRulesPayload,
    initialValues: {
      countryCode: record ? record.countryCode : '',
      warehouseId: record ? record.warehouseId : '',
      warehouseType: record ? record.warehouseType : '',
      priority: record ? record.priority : 0,
      subdivisionCode: record ? record.subdivisionCode : ''
    },
    onSubmit: async (values) => {
      console.log(values);
      try {
        await warehouseFulfillmentRulesPayload.validate(values);
      } catch (err) {
        console.log(err);
        // editWarehouseFormik.setFieldError('warehouseId', 'A warehouse or warehouse type is required to continue');
        // editWarehouseFormik.setFieldError('warehouseType', 'A warehouse or warehouse type is required to continue');
        message.error('A warehouse or warehouse type is required to continue');

        return;
      }
      if (
        formVals?.nfoWarehouseFulfillmentRules?.find(
          (rule) => rule.countryCode === values.countryCode && rule.warehouseType === values.warehouseType && rule.subdivisionCode === values.subdivisionCode && rule.warehouseId === values.warehouseId
        )
      ) {
        message.error('Rule already exists, no duplicates allowed');

        return;
      }

      const foundCountry = countryData?.data[0]?.countries?.find((country) => country.shortName === values.countryCode)?.alpha2Code;

      const subdivisionCode = countryData?.data[0]?.countries
        ?.find((country) => country.shortName === values.countryCode)
        ?.subdivisions.find((state) => state.name === values.subdivisionCode)?.localCode;

      if (isClone) {
        setValues((prev) => ({
          ...prev,
          nfoWarehouseFulfillmentRules: [...value, { ...values, countryCode: foundCountry, subdivisionCode }].map((row, i) => ({
            ...row,
            priority: i + 1
          }))
        }));
        setIsEditOpen(false);
        editWarehouseFormik.resetForm();
        setIsClone(false);

        return;
      }
      setRecord({ ...values, countryCode: foundCountry, subdivisionCode });
      setIsEditOpen(false);
      editWarehouseFormik.resetForm();

      return;
    }
  });

  const handleCancel = (): void => {
    setIsOpen(false);
    setIsEditOpen(false);
    warehouseFormik.resetForm();
    editWarehouseFormik.resetForm();
    setIsClone(false);
  };

  const handleDeleteRow = (record: WarehouseFulfillmentRules): void => {
    if (!formVals.nfoWarehouseFulfillmentRules) return;

    setValues((prev) => ({
      ...prev,
      nfoWarehouseFulfillmentRules: formVals.nfoWarehouseFulfillmentRules
        ? formVals.nfoWarehouseFulfillmentRules.filter((item) => item !== record).map((item, i) => ({ ...item, priority: i + 1 }))
        : undefined
    }));
  };

  const handleEditRow = (record: WarehouseFulfillmentRules, index: number): void => {
    setIndex(index);
    setIsEditOpen(true);
  };
  const handleClone = (record: WarehouseFulfillmentRules, index: number): void => {
    setIndex(index);
    setIsClone(true);
    setIsEditOpen(true);
  };

  const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: DraggableBodyRowProps): JSX.Element => {
    const ref = useRef<HTMLTableRowElement>(null);

    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};

        if (dragIndex === index) {
          return {};
        }

        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
        };
      },
      drop: (item: { index: number }) => {
        moveRow(item.index, index);
      }
    });
    const [, drag] = useDrag({
      type,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });

    drop(drag(ref));

    return <tr ref={ref} className={`${className}${isOver ? dropClassName : ''}`} style={{ cursor: 'move', ...style }} {...restProps} />;
  };
  const cols = [
    {
      dataIndex: 'sort',
      width: 30,
      render: () => <HolderOutlined style={{ cursor: 'grab' }} />
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority'
    },
    {
      title: 'Country',
      dataIndex: 'countryCode',
      key: 'countryCode'
    },
    {
      title: 'State',
      dataIndex: 'subdivisionCode',
      key: 'subdivisionCode'
    },
    {
      title: 'Warehouse Type',
      dataIndex: 'warehouseType',
      key: 'warehouseType'
    },
    {
      title: 'Warehouse',
      dataIndex: 'warehouseId',
      key: 'warehouseId'
    },
    {
      title: 'Actions',
      width: 150,
      align: 'center' as any,
      render: (_: string, record: WarehouseFulfillmentRules, i: number) => (
        <Row justify="center" gutter={[1, 1]}>
          <Col>
            <Tooltip title="Clone Rule">
              <Button size="small" onClick={(): void => handleClone(record, i)} icon={<CloneIcon style={{ height: 25, width: 20 }} />} />
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title="Edit Rule">
              <Button size="small" onClick={(): void => handleEditRow(record, i)} icon={<EditOutlined />} />
            </Tooltip>
          </Col>
          <Col>
            {contextHolder}
            <Tooltip title="Delete Rule">
              <Button
                size="small"
                onClick={(): void => {
                  modal.confirm({
                    title: 'IF YOU CONTINUE, THE CHANGES WILL NOT GO INTO EFFECT UNTIL YOU CLICK SAVE.',
                    icon: <WarningFilled style={{ color: 'red' }} />,
                    okButtonProps: { style: { background: 'red', border: 'none' } },
                    okText: 'Delete',
                    onOk: () => handleDeleteRow(record),
                    prefixCls: 'ant-modal'
                  });
                }}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>
      )
    }
  ];
  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (!formVals.nfoWarehouseFulfillmentRules) return;
      const dragRow = value[dragIndex];

      setValue(
        update(formVals.nfoWarehouseFulfillmentRules, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        }).map((d, i) => ({ ...d, priority: i + 1, key: i }))
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formVals.nfoWarehouseFulfillmentRules, value]
  );

  const components = {
    body: {
      row: DraggableBodyRow
    }
  };

  return (
    <Card
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, padding: 0, margin: 0 }}>
          NFO Fulfillment Rules
        </Typography.Title>
      }
      headStyle={{ background: 'rgb(255, 234, 193)' }}
      style={{ height: '100%' }}
      extra={<Button onClick={(): void => setIsOpen(true)}>Add</Button>}>
      <FormikProvider value={warehouseFormik}>
        <Modal width={400} onCancel={handleCancel} onOk={(): Promise<void> => warehouseFormik.submitForm()} title="Add Warehouse Fulfillment Rule" open={isOpen}>
          {/* <Form layout="vertical"> */}
          <Space direction="vertical" size={15} style={{ width: '100%' }}>
            <CountriesSelectInput stateFieldName="subdivisionCode" countryFieldName="countryCode" />
            <FieldInputSelect formattedOptions={['DC', 'SSL', 'Regional SSL', 'Customer Premise']} fieldName="warehouseType" label="Warehouse Type" />
            <SiteSelectInput />
          </Space>
          {/* </Form> */}
        </Modal>
      </FormikProvider>
      <FormikProvider value={editWarehouseFormik}>
        <Modal
          okButtonProps={{
            disabled: _.isEqual(editWarehouseFormik.initialValues, editWarehouseFormik.values)
          }}
          width={400}
          onCancel={handleCancel}
          onOk={(): Promise<void> => editWarehouseFormik.submitForm()}
          title={!isClone ? 'Edit Warehouse Fulfillment Rule' : 'Add Warehouse Fulfillment Rule'}
          open={isEditOpen}>
          {/* <Form layout="vertical"> */}
          <Space direction="vertical" size={15} style={{ width: '100%' }}>
            <CountriesSelectInput stateFieldName="subdivisionCode" countryFieldName="countryCode" />
            <FieldInputSelect formattedOptions={['DC', 'SSL', 'Regional SSL', 'Customer Premise']} fieldName="warehouseType" label="Warehouse Type" />
            <SiteSelectInput />
          </Space>
          {/* </Form> */}
        </Modal>
      </FormikProvider>
      <DndProvider manager={manager} backend={HTML5Backend}>
        <Table
          locale={{ emptyText: <AddRuleMessage /> }}
          columns={cols}
          pagination={false}
          dataSource={formVals.nfoWarehouseFulfillmentRules}
          components={components}
          rowKey={(record): string => `${record.warehouseId} - ${record.priority}`}
          onRow={(_, index): any => {
            const attr = {
              index,
              moveRow
            };

            return attr as React.HTMLAttributes<any>;
          }}
        />
      </DndProvider>
    </Card>
  );
};
