import { message, Space, Spin } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { CreateTypesControls } from 'components/molecules/CreateTypesControls';
import { CreateTypesForm } from 'components/molecules/CreateTypesForm';
import { RuleTables } from 'components/organisms/RuleTables';
import { FormikProvider, useFormik } from 'formik';
import { CreateSparingRequestType, requestTypePayload } from 'models/SparingRequestTypes';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateSparingRequestConfigurationTypeMutation } from 'redux/services/calvin/sparingRequestConfigTypes';
import { ReduxState } from 'redux/store';

export const CreateTypesPage = (): JSX.Element => {
  const { divisionId } = useParams();
  const navigate = useNavigate();

  const { editTypePayloadId } = useSelector((state: ReduxState) => state.app);

  const [triggerUpdate, { isLoading: isCreating }] = useCreateSparingRequestConfigurationTypeMutation();

  const arr = ['SO']; // Replace with api call when hooked up

  const formik2 = useFormik<CreateSparingRequestType>({
    enableReinitialize: true,
    validationSchema: requestTypePayload,
    initialValues: {
      description: '',
      dimensionFulfillmentRules: [],
      inventoryReservationMethod: 'Automatic',
      nfoWarehouseFulfillmentRules: [],
      isActive: true,
      isOverageAllowed: false,
      name: '',
      salesOrderType: '',
      nbdWarehouseFulfillmentRules: [],
      isNextBusinessDayEnabled: false,
      isLocalDeliveryEnabled: false,
      isNextFlightOutEnabled: false
      // is250: false
    },

    onSubmit: async (values) => {
      try {
        if (!divisionId) return message.error('Division cannot be found');
        const newType = await triggerUpdate({ divisionId, payload: values }).unwrap();

        message.success(`${newType.name} request type successfully created`);
        if (editTypePayloadId) navigate(`/${divisionId}/edit`);
        else navigate(`/${divisionId}/types/${newType.id}/edit`);
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
    }
  });

  return (
    <FormikProvider value={formik2}>
      <Spin spinning={isCreating} indicator={<BlockLoader message="Creating spares request type" direction="loader loader--slideUp" />}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <CreateTypesControls />
          <CreateTypesForm />
          <RuleTables />
        </Space>
      </Spin>
    </FormikProvider>
  );
};
