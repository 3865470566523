import * as yup from 'yup';

export interface SparingRequestTypes {
  auditHistory: string[];
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  description: string;
  dimensionFulfillmentRules: DimensionFulfillmentRules[];
  division: {
    business: {
      dataAreaId: string;
      erpId: string;
      id: string;
      name: string;
    };
    erpId: string;
    id: string;
    name: string;
  };
  documentType: string;
  id: string;
  inventoryReservationMethod: string;
  isNextBusinessDayEnabled: boolean;
  isNextFlightOutEnabled: boolean;
  isLocalDeliveryEnabled: boolean;
  isActive: boolean;
  isDeleted: boolean;
  isLabConfigurationRequired: boolean;
  isOverageAllowed: boolean;
  isReleaseOrderRequired: boolean;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  name: string;
  partitionKey: string;
  partitionKeyDescription: string;
  salesOrderType: string;
  nbdWarehouseFulfillmentRules: WarehouseFulfillmentRules[];
  nfoWarehouseFulfillmentRules: WarehouseFulfillmentRules[];
}

export interface EditSparingRequestType {
  name: string;
  inventoryReservationMethod: string;
  salesOrderType: string;
  description?: string;
  isActive?: boolean;
  isOverageAllowed?: boolean;
  isNextBusinessDayEnabled: boolean;
  isNextFlightOutEnabled: boolean;
  isLocalDeliveryEnabled: boolean;
  dimensionFulfillmentRules?: DimensionFulfillmentRules[];
  nbdWarehouseFulfillmentRules: WarehouseFulfillmentRules[];
  nfoWarehouseFulfillmentRules: WarehouseFulfillmentRules[];
  isReleaseOrderRequired?: boolean;
  isLabConfigurationRequired?: boolean;
  isExpediteApprovalRequired?: boolean;
}

export interface WarehouseFulfillmentRules {
  id?: string;
  countryCode?: string;
  warehouseId?: string;
  warehouseType?: string;
  priority?: number;
  subdivisionCode?: string;
}
export interface DimensionFulfillmentRules {
  id?: string;
  conditionId?: string;
  dispositionId?: string;
  isDefault?: boolean;
  isCommonStock?: boolean;
  ownerId?: string;
  priority?: number;
}

export interface CreateSparingRequestType {
  name: string;
  inventoryReservationMethod: string;
  salesOrderType: string;
  description?: string;
  isActive?: boolean;
  isOverageAllowed?: boolean;
  nbdWarehouseFulfillmentRules?: WarehouseFulfillmentRules[];
  nfoWarehouseFulfillmentRules?: WarehouseFulfillmentRules[];
  dimensionFulfillmentRules?: DimensionFulfillmentRules[];
  isReleaseOrderRequired?: boolean;
  isLabConfigurationRequired?: boolean;
  isExpediteApprovalRequired?: boolean;
  isNextBusinessDayEnabled?: boolean;
  isNextFlightOutEnabled?: boolean;
  isLocalDeliveryEnabled?: boolean;
}

export interface TransportationLanes {
  id?: string;
  auditHistory?: string[];
  minimumDaysTransport?: number;
  minimumServiceDaysExpedite?: number;
  minimumServiceDaysStandard?: number;
  priority?: number;
  deliveryMode: string;
}

export interface SparingRequestTypesParams {
  isOverageAllowed?: boolean;
  nameContains?: string;
  salesOrderTypeEquals?: string;
  dispositionRuleTypeEquals?: string;
  includeInactiveData?: boolean;
  returnInactiveDataOnly?: boolean;
  includeDeletedData?: boolean;
  returnDeletedDataOnly?: boolean;
}

export const requestTypePayload: yup.SchemaOf<CreateSparingRequestType> = yup.object({
  name: yup.string().label('Name').required(),
  inventoryReservationMethod: yup.string().label('').required(),
  salesOrderType: yup.string().label('Sales Order Type').required(),
  description: yup.string().label('Description').notRequired(),
  isActive: yup.boolean().label('Is Active').notRequired(),
  isOverageAllowed: yup.boolean().label('Is Overage Allowed').notRequired(),
  nfoWarehouseFulfillmentRules: yup.array().label('NBD Fulfillment Rules'),
  nbdWarehouseFulfillmentRules: yup.array().label('NFO Fulfillment Rules'),
  dimensionFulfillmentRules: yup.array().label('Dimension Fulfillment Rules').min(1).required(),
  transportationLanes: yup.array().label('transportationLanes'),
  isLabConfigurationRequired: yup.boolean().label('Is Lab Configuration Required').notRequired(),
  isExpediteApprovalRequired: yup.boolean().label('Is Expedite Approval Required').notRequired(),
  isLocalDeliveryEnabled: yup.boolean().label('Is Local Delivery Enabled').notRequired(),
  isNextBusinessDayEnabled: yup.boolean().label('Is NBD Enabled').notRequired(),
  isNextFlightOutEnabled: yup.boolean().label('Is NFO Enabled').notRequired(),
  isReleaseOrderRequired: yup.boolean().label('Is Release Order Required').notRequired()
});

export const fulfillmentRulesPayload: yup.SchemaOf<DimensionFulfillmentRules> = yup.object({
  ownerId: yup.string().label('Owner').required(),
  conditionId: yup.string().label('Condition').required(),
  dispositionId: yup.string().label('Disposition').required(),
  isDefault: yup.boolean().label('Is Default').required(),
  isCommonStock: yup.boolean().label('Is Common Stock').required(),
  priority: yup.number().label('Priority'),
  id: yup.string().notRequired()
});

export const transportationLanesPayload: yup.SchemaOf<TransportationLanes> = yup.object({
  deliveryMode: yup.string().label('Delivery Mode').required(),
  minimumDaysTransport: yup.number().label('Minimum Days Transport').required(),
  minimumServiceDaysExpedite: yup.number().label('Minimum Expidite Days').required(),
  minimumServiceDaysStandard: yup.number().label('Minimum Standard Days').required(),
  auditHistory: yup.array().label('Is Default'),
  priority: yup.number().label('Priority'),
  id: yup.string().notRequired()
});

export const warehouseFulfillmentRulesPayload: yup.SchemaOf<WarehouseFulfillmentRules> = yup
  .object({
    countryCode: yup.string().label('Country').required(),
    warehouseId: yup.string().label('Warehouse').notRequired(),
    warehouseType: yup.string().label('Postal Code').notRequired(),
    subdivisionCode: yup.string().label('State'),
    priority: yup.number().label('Priority'),
    id: yup.string().notRequired()
  })
  .test('oneOfTwoFieldsRequired', 'Either Field 1 or Field 2 is required', (values): boolean => {
    const { warehouseId, warehouseType } = values;

    if (!warehouseId && !warehouseType) return false;

    return true;
  });
