import { Card, Col, Form, Row, Space, Typography } from 'antd';
import { FieldInputName } from 'components/UI/FormItems/FieldInputName';
import { FieldInputSelect } from 'components/UI/FormItems/FieldInputSelect';
import { SwitchInputCard } from 'components/UI/FormItems/SwitchInputCard';
import { SwitchInputCardMock } from 'components/UI/FormItems/SwitchInputCardMock';
import { TextAreaInput } from 'components/UI/FormItems/TextAreaInput';
import { InventoryReservationMethodInput } from 'components/atoms/InventoryReservationMethodInput';
import { NBDFulfillmentTable } from './NBDFulfillmentTable';

export const CreateTypesForm = (): JSX.Element => {
  return (
    <Row gutter={[10, 0]}>
      <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={14}>
        <Card
          style={{ height: '100%' }}
          headStyle={{ background: 'rgb(206, 213, 242)' }}
          title={
            <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
              General
            </Typography.Title>
          }>
          <Row gutter={[30, 0]}>
            <Col span={8}>
              <Form layout="vertical">
                <Space direction="vertical" style={{ width: '100%' }} size={15}>
                  <Col>
                    <FieldInputName fieldName="name" label="Name" />
                  </Col>
                  {/* <Col>
                    <DispositionRuleSelectInput fieldName="dispositionRuleType" label="Rule Type" />
                  </Col> */}
                  <Col>
                    <FieldInputSelect formattedOptions={['SPAR']} fieldName="salesOrderType" label="Sales Order Type" />
                  </Col>
                  <Col>
                    <InventoryReservationMethodInput />
                  </Col>
                  <Col>
                    <TextAreaInput fieldName="description" label="Description" />
                  </Col>
                </Space>
              </Form>
            </Col>
            <Col span={8}>
              <Form layout="vertical">
                <Space direction="vertical" style={{ width: '100%' }} size={15}>
                  <SwitchInputCard fieldName="isActive" label="Is Active" />
                  <SwitchInputCard fieldName="isOverageAllowed" label="Is Overage Allowed" />
                  <SwitchInputCard fieldName="isLabConfigurationRequired" label="Is Lab Configuration Utilized" />
                  <SwitchInputCard fieldName="isReleaseOrderRequired" label="Is Released Order Utilized" />
                </Space>
              </Form>
            </Col>
            <Col span={8}>
              <Form layout="vertical">
                <Space direction="vertical" style={{ width: '100%' }} size={15}>
                  <SwitchInputCard fieldName="isNextFlightOutEnabled" label="Allow Next Flight Out Delivery" />
                  <SwitchInputCard fieldName="isNextBusinessDayEnabled" label="Allow Next Busniess Day Delivery" />
                  <SwitchInputCard fieldName="isLocalDeliveryEnabled" label="Allow Local Delivery" />
                  <SwitchInputCardMock label="Allow Local Delivery Up to 250 Miles" />
                </Space>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={10}>
        <NBDFulfillmentTable />
      </Col>
    </Row>
  );
};
