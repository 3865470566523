import * as yup from 'yup';

export interface Approvals {
  isActive?: boolean;
  orderCostThreshold?: number;
  approverUserIds: string[];
  id?: string;
}

export interface Shipments {
  automaticallySetShippingContactInfoToUsersContactInfo: boolean;
  isExpeditedShipmentAllowed: boolean;
  isExpeditedShipmentApprovalRequired: boolean;
  isShipCompleteRequired: boolean;
}

export interface SparingRequestConfiguration {
  addressDetails: {
    isManualAddressAllowedToBeAddedToPostOffice: boolean;
    istManualAddressEntryAllowed: boolean;
    displayAddressesByDefault: boolean;
    outageLocationTypes: string[];
    shippingAddressTypes: string[];
  };
  auditHistory: string[];
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  division: {
    business: {
      dataAreaId: string;
      erpId: string;
      id: string;
      name: string;
    };
    erpId: string;
    id: string;
    name: string;
  };
  documentType: string;
  id: string;
  isNextFlightOutApprovalRequired: boolean;
  reference1Label: string;
  reference2Label: string;
  isStandardCostDisplayed: boolean;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  notifications: {
    releaseNotifications: {
      areReleasedNotificationsEnabled: boolean;
      emailAddresses: string[];
    };
    shipmentNotifications: {
      areShipmentNotificationsEnabled: boolean;
      emailAddresses: string[];
    };
  };
  orderApprovals: [
    {
      approverUserIds: string[];
      id: string;
      isActive: boolean;
      orderCostThreshold: number;
    }
  ];
  partitionKey: string;
  partitionKeyDescription: string;
  shipmentDetails: {
    automaticallySetShippingContactInfoToUsersContactInfo: boolean;
    carrierAccountNumber: string;
    defaultDefectiveReturnDeliveryMode: string;
    defaultNextBusinessDayDeliveryMode: string;
  };
}

export interface UpsertSparingRequestConfigurationPayload {
  orderApprovals?: {
    approverUserIds: string[];
    id: string;
    isActive: boolean;
    orderCostThreshold: number;
  }[];
  addressDetails: {
    outageLocationTypes: string[];
    isManualAddressAllowedToBeAddedToPostOffice: boolean;
    istManualAddressEntryAllowed: boolean;
    displayAddressesByDefault: boolean;
    shippingAddressTypes: string[];
  };
  isStandardCostDisplayed: boolean;
  isNextFlightOutApprovalRequired: boolean;
  reference1Label: string;
  reference2Label: string;
  notifications: {
    releaseNotifications: {
      areReleasedNotificationsEnabled: boolean;
      emailAddresses: string[];
    };
    shipmentNotifications: {
      areShipmentNotificationsEnabled: boolean;
      emailAddresses: string[];
    };
  };
  shipmentDetails: {
    automaticallySetShippingContactInfoToUsersContactInfo: boolean;
    carrierAccountNumber: string;
    defaultDefectiveReturnDeliveryMode: string;
    defaultNextBusinessDayDeliveryMode: string;
  };
}

export interface RequiredConfigFields {
  allowedDeploymentRequestTypes: string[];
}

export enum RuleFilter {
  Disposition = 'Disposition',
  AllProjects = 'All Projects',
  SpecificProjects = 'Specific Projects'
}

export const approvalsPayload: yup.SchemaOf<Approvals> = yup.object({
  isActive: yup.boolean().label('Is Active').required(),
  orderCostThreshold: yup.number().label('Order Cost Threshold').required(),
  approverUserIds: yup.array().label('Approved User Ids').min(1).required(),
  id: yup.string()
});
// export const upsertConfigurationPayload: yup.SchemaOf<RequiredConfigFields> = yup.object({
//   allowedDeploymentRequestTypes: yup.array().min(1).required('Allowed deployment request types must have at least one option selected')
// });
export const notifications: yup.SchemaOf<RequiredConfigFields> = yup.object({
  allowedDeploymentRequestTypes: yup.array().min(1).required('Allowed deployment request types must have at least one option selected')
});
