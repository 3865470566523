import { skipToken } from '@reduxjs/toolkit/dist/query';
import { List, Space } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import RecyclingBinCard from 'components/atoms/RecyclingBinCard';
import { RecycleControls } from 'components/organisms/RecycleControls';
import { SparingRequestTypes } from 'models/SparingRequestTypes';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetSparingRequestConfigurationTypesQuery } from 'redux/services/calvin/sparingRequestConfigTypes';
import { ReduxState } from 'redux/store';

export const TypesRecycleBinPage = (): JSX.Element => {
  const { divisionId } = useParams();
  const { sparingRequestTypesParams } = useSelector((state: ReduxState) => state);

  const { data, isLoading, isFetching } = useGetSparingRequestConfigurationTypesQuery(divisionId ? { divisionId, params: { ...sparingRequestTypesParams, returnDeletedDataOnly: true } } : skipToken);

  if (isLoading || isFetching) return <BlockLoader direction="loader loader--slideUp" />;

  return (
    <Space direction="vertical">
      <RecycleControls />
      <List dataSource={data?.data} grid={{ gutter: [5, 5] as any, column: 4 }} renderItem={(item: SparingRequestTypes): React.ReactNode => <RecyclingBinCard type={item} />} />
    </Space>
  );
};
