import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SparingRequestTypes } from 'models/SparingRequestTypes';

interface InitialState {
  selectedTypes: SparingRequestTypes[];
}

const initialState: InitialState = {
  selectedTypes: []
};

export const selectedTypes = createSlice({
  name: 'selectedTypes',
  initialState,
  reducers: {
    setSelectedTypes: (state, { payload }: PayloadAction<SparingRequestTypes[]>) => {
      state.selectedTypes = payload;
    },
    addSelectedTypes: (state, { payload }: PayloadAction<SparingRequestTypes>) => {
      // console.log(state, 'look');

      state.selectedTypes = [...state.selectedTypes, payload];
    },
    removeSelectedTypes: (state, { payload }: PayloadAction<SparingRequestTypes>) => {
      state.selectedTypes = state.selectedTypes.filter((item) => item.id !== payload.id);
    }
  }
});

export const { setSelectedTypes, addSelectedTypes, removeSelectedTypes } = selectedTypes.actions;
