import { Space, Spin } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { useGetWarehousesQuery } from 'redux/services/kingLivingston/useKingstonLivingstonService';
import { FieldInputSelect } from './FieldInputSelect';

export const SiteSelectInput = (): JSX.Element => {
  const { data: warehouses, isLoading, isFetching } = useGetWarehousesQuery({ dataAreaId: 'MDSI', overrideSkipTake: true });
  const warehouseOptionsArr = warehouses?.data?.map((warehouse) => warehouse.warehouseId);

  return (
    <Spin indicator={<BlockLoader small direction="loader loader--slideUp" />} spinning={isFetching || isLoading}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <FieldInputSelect formattedOptions={warehouseOptionsArr} fieldName="warehouseId" label="Warehouse" />
      </Space>
    </Spin>
  );
};
