import { skipToken } from '@reduxjs/toolkit/dist/query';
import { message, Space, Spin } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { EditTypeControls } from 'components/molecules/EditTypeControls';
import { EditTypesForm } from 'components/molecules/EditTypeForm';
import { RuleTables } from 'components/organisms/RuleTables';
import { FormikProvider, useFormik } from 'formik';
import { EditSparingRequestType } from 'models/SparingRequestTypes';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSparingRequestConfigurationTypeQuery, useUpdateSparingRequestConfigurationTypeMutation } from 'redux/services/calvin/sparingRequestConfigTypes';
import { ReduxState } from 'redux/store';

export const EditTypesPage = (): JSX.Element => {
  const navigate = useNavigate();

  const { isClose } = useSelector((state: ReduxState) => state.app);

  const { divisionId, typeId } = useParams();
  const { data: requestType, isLoading, isFetching } = useGetSparingRequestConfigurationTypeQuery(divisionId && typeId ? { divisionId, typeId } : skipToken);

  const [triggerUpdate, { isLoading: isUpdating }] = useUpdateSparingRequestConfigurationTypeMutation();

  const formik = useFormik<EditSparingRequestType>({
    enableReinitialize: true,
    initialValues: {
      name: requestType?.name ?? '',
      description: requestType?.description ?? '',
      inventoryReservationMethod: requestType?.inventoryReservationMethod ?? '',
      salesOrderType: requestType?.salesOrderType ?? '',
      isActive: requestType?.isActive ?? false,
      isLabConfigurationRequired: requestType?.isLabConfigurationRequired ?? false,
      isReleaseOrderRequired: requestType?.isReleaseOrderRequired ?? false,
      isOverageAllowed: requestType?.isOverageAllowed ?? false,
      nbdWarehouseFulfillmentRules: requestType?.nbdWarehouseFulfillmentRules ?? [],
      nfoWarehouseFulfillmentRules: requestType?.nfoWarehouseFulfillmentRules ?? [],
      dimensionFulfillmentRules: requestType?.dimensionFulfillmentRules ?? [],
      isLocalDeliveryEnabled: requestType?.isLocalDeliveryEnabled ?? false,
      isNextBusinessDayEnabled: requestType?.isNextBusinessDayEnabled ?? false,
      isNextFlightOutEnabled: requestType?.isNextFlightOutEnabled ?? false
    },
    onSubmit: async (values) => {
      if (!divisionId || !typeId) return;
      try {
        const updatedType = await triggerUpdate({ divisionId, sparingRequestTypeId: typeId, payload: { ...requestType, ...values } }).unwrap();

        message.success(`Type: ${updatedType.name} successfully updated`);
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } })?.data.errorMessage ?? 'Could not be updated at this time');
      }
    }
  });

  if (isLoading || isFetching) return <BlockLoader direction="loader loader--slideUp" />;

  return (
    <FormikProvider value={formik}>
      <Spin spinning={isUpdating} indicator={<BlockLoader message="Updating spares request type" direction="loader loader--slideUp" />}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <EditTypeControls />
          <EditTypesForm />
          <RuleTables />
        </Space>
      </Spin>
    </FormikProvider>
  );
};
