import { Button, Col, Form, List, Row, Space, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { CurrencyInput } from 'components/UI/FormItems/CurrencyInput';
import { SelectInput } from 'components/UI/FormItems/SelectInput';
import { SwitchInput } from 'components/UI/FormItems/SwitchInput';
import { CaretSpinIcon } from 'components/atoms/CaretSpinIcon';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { Approvals, UpsertSparingRequestConfigurationPayload, approvalsPayload } from 'models/SparingRequestConfig';
import React, { LegacyRef, MouseEvent, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { ApprovalListItem } from './ApprovalsListItem';
const testIds = ['user1', 'user2', 'user3', 'user4'];

export const OrdersApprovalCard: React.FC = () => {
  const { values } = useFormikContext<UpsertSparingRequestConfigurationPayload>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [showOrdersApproval, setShowOrdersApproval] = useState(false);

  const [{ value }, , { setValue: setOrderApprovals }] = useField<string>('orderApprovals');

  const formik1 = useFormik<Approvals>({
    enableReinitialize: true,
    validationSchema: approvalsPayload,
    initialValues: {
      isActive: false,
      orderCostThreshold: undefined,
      approverUserIds: [],
      id: ''
    },
    onSubmit: (values) => {
      setOrderApprovals([...value, values] as any);
      setIsModalVisible(false);
      formik1.resetForm();
    }
  });

  const handleSubmit = (): void => {
    formik1.submitForm();
  };

  const handleToggleShowOrdersApproval = (ev: MouseEvent<HTMLElement>): void => {
    ev.preventDefault();
    ev.stopPropagation();
    setShowOrdersApproval((prevState) => !prevState);
  };

  const handleAddClick = (ev: MouseEvent<HTMLElement>): void => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsModalVisible(true);
    if (showOrdersApproval) return;
    setShowOrdersApproval(false);
  };

  const [ref, { height }] = useMeasure();

  const expandAnimation = useSpring({
    config: { friction: 12 },
    height: showOrdersApproval ? height : 0
  });

  const handleCancel = (): void => {
    setIsModalVisible(false);
    formik1.resetForm();
  };

  /* ******************** Renderer ******************** */
  return (
    <Col span={24} style={{ marginBottom: 5 }}>
      <Row onClick={handleToggleShowOrdersApproval} style={{ padding: 15, background: '#b0b2bc', marginBottom: 5, borderRadius: 5, cursor: 'pointer' }} justify="space-between">
        <Col>
          <Typography.Title style={{ fontWeight: 400, padding: 0, margin: 0, fontSize: 22 }} level={3}>
            Order Approvals
          </Typography.Title>
        </Col>
        <Row align="middle">
          <Button onClick={handleAddClick}>Add Approval Threshhold</Button>
          <Button style={{ width: 115 }} type="text" onClick={handleToggleShowOrdersApproval} key="show-divisions" icon={<CaretSpinIcon isOpen={showOrdersApproval} />}>
            {showOrdersApproval ? 'Collapse' : 'Expand'}
          </Button>
        </Row>
      </Row>
      <animated.div style={{ ...{ minHeight: 10, overflow: 'hidden', width: '100%' }, ...expandAnimation }}>
        {showOrdersApproval && (
          <div ref={ref as LegacyRef<HTMLDivElement>} style={{ width: '100%' }}>
            <List
              style={{ width: '100%' }}
              grid={{ gutter: 5, column: 5, xs: 1, sm: 1, md: 2, lg: 3, xl: 4, xxl: 5 }}
              dataSource={values.orderApprovals}
              renderItem={(item, i): React.ReactNode => <ApprovalListItem index={i} approval={item} />}
            />
          </div>
        )}
      </animated.div>
      <FormikProvider value={formik1}>
        <Modal width={350} onCancel={handleCancel} open={isModalVisible} onOk={handleSubmit} title="Add Order Approval">
          <Form layout="vertical">
            <Space direction="vertical" style={{ width: '100%' }} size={18}>
              <CurrencyInput fieldName="orderCostThreshold" label="Order Cost Threshold" />
              <SelectInput mode="multiple" selectOptions={testIds} fieldName="approverUserIds" label="Approved Users" />
              <SwitchInput fieldName="isActive" label="Is Active" />
            </Space>
          </Form>
        </Modal>
      </FormikProvider>
    </Col>
  );
};
