import { Col, Row } from 'antd';
import { AddressesCard } from 'components/molecules/AddressesCard';
import { GeneralCard } from 'components/molecules/GeneralCard';
import { NotificationsCard } from 'components/molecules/NotificationsCard';
import { OrdersApprovalCard } from 'components/molecules/OrdersApprovalCard';
import { ShipmentsCard } from 'components/molecules/ShipmentsCard';
import { SparingRequestConfiguration } from 'models/SparingRequestConfig';
import { SparesRequestTypesList } from './SparesRequestTypesList';

interface Props {
  config?: SparingRequestConfiguration;
}

export const EditPageCards: React.FC<Props> = ({ config }: Props): JSX.Element => {
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={8} xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
          <GeneralCard />
        </Col>
        <AddressesCard />
        <ShipmentsCard />
      </Row>
      <Row gutter={4}>
        <SparesRequestTypesList config={config} />
        <NotificationsCard />
        <OrdersApprovalCard />
      </Row>
    </>
  );
};
