import { List } from 'antd';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { BusinessListItem } from 'components/molecules/BusinessListItem';
import { Business } from 'models/Business';
import { Division } from 'models/Division';
import { useMemo } from 'react';
import { useGetBusinessesQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { useAppSelector } from 'redux/store';

export const BusinessList = (): JSX.Element => {
  const { data, isLoading, isFetching } = useGetBusinessesQuery({ includeDivisions: true });

  const { customerSearch } = useAppSelector((state) => state.businessList);

  const businessData = data?.data;
  /* ******************** Render ******************** */

  const filteredData = useMemo(() => {
    let returnData: Business[] = [];

    // If business and divisions loaded
    if (businessData) {
      for (const business of businessData) {
        // Map the divisions
        let fullDivisions: Division[] = [];

        for (const division of business.divisions) {
          fullDivisions = [...fullDivisions, ...[division]] as any;
        }

        // Add business filtered for customer search
        returnData = [...returnData, { ...business, divisions: fullDivisions }].map((business) => ({
          ...business,
          divisions: business.divisions.filter((division) => !customerSearch || division.name.toLowerCase().includes(customerSearch) || division.erpId.toLowerCase().includes(customerSearch))
        }));
      }
    }

    // Only return the search values that contain divisions
    const filteredReturnData = customerSearch?.length ? returnData.filter((business) => business.divisions.length) : returnData;

    return filteredReturnData;
  }, [businessData, customerSearch]);

  if (isLoading || isFetching) return <LoaderWithMessage loadingMessage="Loading customers.." />;

  return (
    <>
      <List dataSource={filteredData} renderItem={(item): JSX.Element => <BusinessListItem business={item} />} rowKey={(item: any): string => item.id} />
    </>
  );
};
