import { Col, Row } from 'antd';
import { DimensionFulfillmentTable } from 'components/molecules/DimensionFullfilmentTable.tsx';
import { NFOFulfillmentTable } from 'components/molecules/NFOFulfillmentTable';

export const RuleTables = (): JSX.Element => {
  return (
    <Row gutter={[10, 10]} style={{ marginBottom: 32 }}>
      <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
        <DimensionFulfillmentTable />
      </Col>
      <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
        <NFOFulfillmentTable />
      </Col>
    </Row>
  );
};
