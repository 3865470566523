import { Input, Spin } from 'antd';
import { Field, FieldProps, useFormikContext } from 'formik';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetSparingRequestConfigurationTypesQuery } from 'redux/services/calvin/sparingRequestConfigTypes';
import { ReduxState } from 'redux/store';
import { FormLabel } from './FormLabel';

interface Props {
  fieldName: string;
  placeholder?: string;
  type?: string;
  label: string;
  reqName?: string;
}

export const FieldInputName: FC<Props> = ({ fieldName, placeholder, type, label, reqName }) => {
  const { handleSubmit } = useFormikContext();
  const { sparingRequestTypesParams } = useSelector((state: ReduxState) => state);
  const { divisionId } = useParams();
  const [nameError, setNameError] = useState<string | undefined>();

  const { data: testTypes, isLoading, isFetching } = useGetSparingRequestConfigurationTypesQuery({ divisionId: divisionId as string, params: sparingRequestTypesParams }, { skip: !divisionId });

  const filteredTypes = testTypes?.data.filter((type) => type.name !== reqName);

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Spin spinning={isLoading || isFetching}>
      <Field name={fieldName} placeholder={placeholder}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta
        }: FieldProps): JSX.Element => {
          const handleError = (): string => {
            if (nameError && meta.touched) return 'field-error-show';
            if (meta.touched && meta.error) return 'field-error-show';
            else return 'field-error-hide';
          };

          if (filteredTypes?.some((type) => type.name === field.value)) setNameError('Deployment Request Type name already exists.');
          else setNameError(undefined);

          return (
            <div>
              <FormLabel label={label} />
              <Input onKeyDown={handleEnter} onFocus={() => setFieldTouched(fieldName, false)} style={{ borderColor: meta.error && meta.touched ? '#dc3545' : undefined }} type={type} {...field} />
              <div className={handleError()}>{nameError ?? meta.error}</div>
            </div>
          );
        }}
      </Field>
    </Spin>
  );
};
