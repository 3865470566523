import React, { useEffect } from 'react';
import { setNameContains } from 'redux/services/calvin/requestTypeParams';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from '../Inputs';

export const NameContainsFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setNameContains(value || undefined));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setNameContains(undefined));
    };
  }, [dispatch]);

  return <FancyInput placeholder="Name" onChange={handleChange} />;
};
