import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Row, Select, SelectProps, Spin } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { Field, FieldProps } from 'formik';
import { CSSProperties, FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGetDivisionQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { Country } from 'redux/services/chuckieSue/models/worlds';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  type?: string;
  label: string;
  labelStyle?: CSSProperties;
  readOnly?: boolean;
  disabled?: boolean;
  formattedOptions?: string[];
  countryOptions?: Country[];
}

export const OwnersSelect: FC<Props> = ({ fieldName, label, labelStyle, ...rest }) => {
  const { divisionId } = useParams();

  const { data, isLoading, isFetching } = useGetDivisionQuery(divisionId ?? skipToken);

  return (
    <Field name={fieldName}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        const handleChange = (val: string): void => {
          if (fieldName === 'countryCode') setFieldValue('subdivisionCode', '');
          if (fieldName === 'siteId') setFieldValue('warehouseId', '');
          setFieldValue(fieldName, val);
        };

        return (
          <Spin style={{ width: '100%' }} spinning={isFetching || isLoading} indicator={<BlockLoader small direction="loader loader--slideUp" />}>
            <Row style={{ width: '100%' }}>
              <FormLabel style={labelStyle} label={label} />

              <Select
                {...field}
                showSearch
                dropdownMatchSelectWidth
                allowClear
                onClear={(): void => {
                  setFieldTouched(fieldName, true);
                }}
                filterSort={(optionA, optionB): number => (optionA?.label?.toString() ?? '').toLowerCase().localeCompare((optionB?.label?.toString() ?? '').toLowerCase())}
                onChange={handleChange}
                status={meta.error && meta.touched ? 'error' : undefined}
                onFocus={(): void => setFieldTouched(fieldName, false)}
                onBlur={(): void => setFieldTouched(fieldName, true)}
                style={{ width: '100%' }}
                options={data?.assignedOwners.map((owner) => ({ label: owner, value: owner }))}
                {...rest}
              />
              <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
            </Row>
          </Spin>
        );
      }}
    </Field>
  );
};
