import { Card, Col, Space, Typography } from 'antd';
import { FieldInput } from 'components/UI/FormItems/FieldInput';
import { SwitchInputCard } from 'components/UI/FormItems/SwitchInputCard';
import { useFormikContext } from 'formik';
import { SparingRequestConfiguration } from 'models/SparingRequestConfig';
import React from 'react';

export const ShipmentsCard: React.FC = () => {
  const { values } = useFormikContext<SparingRequestConfiguration>();

  /* ******************** Renderer ******************** */
  return (
    <Col span={8} xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
      <Card
        title={
          <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
            Shipments
          </Typography.Title>
        }
        headStyle={{ background: '#18a79984' }}
        bodyStyle={{ padding: 20 }}
        style={{ height: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }} size={20}>
          <SwitchInputCard fieldName="shipmentDetails.automaticallySetShippingContactInfoToUsersContactInfo" label="Set Shipping Contact Info to User's" />
          <FieldInput fieldName="shipmentDetails.defaultNextBusinessDayDeliveryMode" label="Default NBD Delivery" />
          <FieldInput fieldName="shipmentDetails.carrierAccountNumber" label="Carrier Account Number" />
        </Space>
      </Card>
    </Col>
  );
};
